import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Contact({ data }) {
  return (
    <Layout>
      <SEO title={`Contact the NFL DFS Blog`} description={`Contact us via Twitter`} />
      <h1>We'd love to talk! Get at {data.site.siteMetadata.title} on Twitter!</h1>
      <p>
        <a href="https://twitter.com/DFSNFLTeam">DFS NFL on Twitter</a>
      </p>
    </Layout>
  )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`